.history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  backdrop-filter: blur(5px);
}

.history-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.history-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: #191919;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid #ecdbba;
  border-bottom: none;
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  box-sizing: border-box;
}

.history-overlay.visible .history-modal {
  transform: translateY(0);
}

.history-header {
  padding: 1.25rem 1rem;
  border-bottom: 2px solid #ecdbba;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191919;
  position: relative;
}

.history-header h2 {
  margin: 0;
  
  font-size: 1.5rem;
  color: #ecdbba;
  flex: 1;
  padding-left: 0.5rem;
}

.history-header::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 4px;
  background-color: #ecdbba;
  border-radius: 2px;
  opacity: 0.3;
}

.history-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.history-actions button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #ecdbba;
  color: #ecdbba;
  cursor: pointer;
  transition: all 0.15s ease;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.history-actions .summary-button {
  font-family: "Outfit", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;
  padding: 0 1rem;
  border-radius: 6px;
  white-space: nowrap;
}

.history-actions .clear-button,
.history-actions .close-button {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.history-actions button:active {
  background-color: rgba(236, 219, 186, 0.15);
  transform: scale(0.97);
}

.history-actions button:active .svg-history-icon {
  fill: rgba(236, 219, 186, 0.8);
}

@media (hover: hover) {
  .history-actions button:hover {
    background-color: rgba(236, 219, 186, 0.1);
  }
}

.svg-history-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  fill: #ecdbba;
  transition: fill 0.2s ease;
}

.history-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  -webkit-overflow-scrolling: touch;
}

.history-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  background-color: #2d2d2d;
  transition: transform 0.2s ease;
}

.history-item:hover {
  transform: translateX(5px);
}

.history-item.nosotros {
  border-left: 4px solid #4287f5;
}

.history-item.ellos {
  border-left: 4px solid #C84B31;
}

.team-indicator {
  font-weight: bold;
  font-size: 1.2rem;
  width: 30px;
  text-align: center;
  color: #ecdbba;
}

.action-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 1rem;
}

.action {
  color: #ecdbba;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.points {
  font-weight: bold;
  font-size: 1.1rem;
}

.points.positive {
  color: #6A9F58;
}

.points.negative {
  color: #C84B31;
}

.timestamp {
  color: #888;
  font-size: 0.9rem;
}

.summary-container {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.summary-team {
  background-color: #2d2d2d;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  color: #ecdbba;
  border: 1px solid rgba(236, 219, 186, 0.1);
}

.summary-team.nosotros {
  border-left: 4px solid #4287f5;
}

.summary-team.ellos {
  border-left: 4px solid #C84B31;
}

.summary-team h3 {
  margin: 0 0 .8rem 0;
  color: #ecdbba;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  
}

.summary-team p {
  margin: .1rem 0;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
}

.summary-team p span:first-child {
  color: rgba(236, 219, 186, 0.7);
  font-weight: 400;
}

.summary-team p span:last-child {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: "Outfit", sans-serif;
}

.summary-button:hover {
  background-color: #2d2d2d;  
  border: 1px solid #ecdbba;
  color: #ecdbba;
  transform: scale(1.02);
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;


}

.summary-button {
  background-color: transparent;
  border: 1px solid #ecdbba;
  padding: .5rem .5rem;
  font-size: .8rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .history-modal {
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 100%);
    border: 2px solid #ecdbba;
    border-bottom: none;
  }
  
  .history-overlay.visible .history-modal {
    transform: translate(-50%, 0);
  }

  .history-header {
    padding: 1.5rem;
  }

  .history-header h2 {
    font-size: 1.75rem;
  }

  .history-actions {
    gap: 0.75rem;
  }

  .history-actions .summary-button {
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1.25rem;
  }

  .history-actions .clear-button,
  .history-actions .close-button {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .svg-history-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  .summary-container {
    padding: 1.5rem;
    gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .summary-team {
    padding: 2rem;
  }

  .summary-team h3 {
    font-size: 1.5rem;
  }

  .summary-team p {
    font-size: 1.125rem;
  }

  .summary-team p span:last-child {
    font-size: 1.5rem;
  }
}
  