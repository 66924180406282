.counter-body-nos {

  align-items: center;
  border-right: 2px solid #ecdbba;
}

.counter-body-ellos, .counter-body-nos {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinea los elementos al inicio */
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 50vw;
  
}

.counter-body-ellos {
  border-left: 2px solid #ecdbba;
  
}

.counter-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Centra los elementos verticalmente */
  width: 100%; /* Asegura que ocupe el ancho total */
  gap: .5rem; /* Espacio entre el contador y los botones */
  
  
}

.counter-button {
  height: 65px;
  width: 65px;
  align-items: center;
  text-align: center;
  border-style: none;
  border-radius: 100%;
  background-color: #2d4263;
  margin: 0 0.7em 0 0.7em;
  color: #ecdbba;
  font-size: 1em;
  line-height: 0;
  cursor: pointer;
  font-weight: bold;
  justify-content: center;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

@keyframes buttonPress {
  0% {
    transform: scale(1);
    background-color: #2d4263;
  }
  50% {
    transform: scale(0.94);
    background-color: #2d4263;
  }
  100% {
    transform: scale(1);
    background-color: #2d4263;
  }
}

.counter-button:active {
  animation: buttonPress 0.15s ease forwards;
  -webkit-animation: buttonPress 0.15s ease forwards;
}

/* Prevenir que el estado active se quede pegado en iOS */
.counter-button:active:after {
  content: "";
  display: none;
}



.counter-points {
  margin-top: 2dvh;
}

.counter-title {
  width: 100%;
  /* border-bottom:  3px solid #fafafa; */
  text-align: center;
  background-color: #2d4263;

  color: #ecdbba;
}

.counter-title h2 {
  font-size: 1.3em;
  margin: 0;
  padding: 0.3em;
}

.stage-indicator h3 {
  font-size: 1em;
  font-weight: 400;
}

.board {
  flex: 1; /* El contenido ocupa el espacio restante */
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}



.buttons-row {
  display: flex;
  flex-direction: row; /* Mantiene los botones en una fila horizontal */
  gap: 0.5rem; /* Espacio entre los botones */
}

.stage-indicator {
  width: 100%;
  text-align: center;
}

.svg-canvas {
  width: 100px;
  height: 310px;
  gap: 2rem;
}

@media screen and (max-height: 600px) {
  .counter-buttons-container {
    flex-direction: column; /* Asegura que los botones se vean en una columna si es necesario */
    gap: 0.5rem; /* Reduce el espacio entre los botones y el contador */
    margin: 0;
  }

  .counter-points h1 {
    font-size: 1.5rem; /* Ajusta el tamaño del número */
  }

  .counter-button {
    height: 60px; /* Reduce el tamaño de los botones */
    width: 60px;
  }

  .footer {
    margin-top: 0;
    
  }

  .svg-canvas {
    margin: 0;
    padding: 0;
    width: 200px;
    height: 250px;
  }
}
