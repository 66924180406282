.changelog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  backdrop-filter: blur(5px);
}

.changelog-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.changelog-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: #191919;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid #ecdbba;
  border-bottom: none;
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  box-sizing: border-box;
}

.changelog-overlay.visible .changelog-modal {
  transform: translateY(0);
}

.changelog-header {
  padding: 1.25rem 1rem;
  border-bottom: 2px solid #ecdbba;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191919;
  position: relative;
}

.changelog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #ecdbba;
  flex: 1;
  padding-left: 0.5rem;
}

.changelog-header::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 4px;
  background-color: #ecdbba;
  border-radius: 2px;
  opacity: 0.3;
}

.changelog-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.changelog-actions button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #ecdbba;
  color: #ecdbba;
  cursor: pointer;
  transition: all 0.15s ease;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.changelog-actions .close-button  {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.changelog-actions .github-button {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ecdbba;
  box-sizing: border-box;
  transition: all 0.15s ease;
  
}



.changelog-actions button:active {
  background-color: rgba(236, 219, 186, 0.15);
  transform: scale(0.95);
}

.changelog-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(236, 219, 186, 0.2) transparent;
}

.changelog-content::-webkit-scrollbar {
  width: 6px;
}

.changelog-content::-webkit-scrollbar-track {
  background: transparent;
}

.changelog-content::-webkit-scrollbar-thumb {
  background-color: rgba(236, 219, 186, 0.2);
  border-radius: 3px;
}

.changelog-release {
  background-color: #2d2d2d;
  padding: 1.5rem;
  border-radius: 16px;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(236, 219, 186, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.changelog-release::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-left: 4px solid #3476e4;
  opacity: 0.8;
}

.changelog-release:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-color: #3476e4;
}

.release-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(236, 219, 186, 0.1);
}

.release-header h3 {
  margin: 0;
  color: #ecdbba;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.release-header h3::before {
  
  font-size: 0.8em;
  color: rgba(236, 219, 186, 0.5);
  font-weight: 400;
}

.release-date {
  color: rgba(236, 219, 186, 0.5);
  font-size: 0.875rem;
  background-color: rgba(236, 219, 186, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-weight: 500;
}

.release-changes {
  margin: 0;
  padding-left: .5rem;
  list-style-type: none;
}

.release-changes li {
  color: rgba(236, 219, 186, 0.8);
  margin-bottom: 0.75rem;
  position: relative;
  line-height: 1.4;
  padding-left: 1.5rem;
  transition: all 0.2s ease;
}

.release-changes li:hover {
  color: #ecdbba;
  transform: translateX(4px);
}

.release-changes li::before {
  content: "→";
  color: #4287f5;
  position: absolute;
  left: 0;
  font-weight: bold;
  opacity: 0.8;
}

@media (min-width: 768px) {
  .changelog-modal {
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 100%);
    border: 2px solid #ecdbba;
    border-bottom: none;
  }
  
  .changelog-overlay.visible .changelog-modal {
    transform: translate(-50%, 0);
  }

  .changelog-header {
    padding: 1.5rem;
  }

  .changelog-header h2 {
    font-size: 1.75rem;
  }

  .changelog-actions {
    gap: 0.75rem;
  }

  .changelog-actions .close-button {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }

  

  .changelog-release {
    padding: 2rem;
  }

  .release-header h3 {
    font-size: 1.5rem;
  }

  .release-date {
    font-size: 1rem;
    padding: 0.35rem 1rem;
  }

  .release-changes li {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}

@media (hover: hover) {
  .changelog-actions button:hover {
    background-color: rgba(236, 219, 186, 0.1);
    border-color: rgba(236, 219, 186, 0.3);
  }
} 